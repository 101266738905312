<template>
  <div>
    <Page :tabList="tabList"></Page>
  </div>
</template>

<script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabList: [
        { name: "会员等级设置", path: "/membership-level" },
        { name: "会员管理", path: "/level-mange" },
      ],
    };
  },
};
</script>

<style>
</style>